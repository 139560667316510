import { useEffect, useRef, useState } from 'react';
import Component from '../../components/mail';
import axios from 'axios';
import { Toast } from 'primereact/toast';

export default function Mail() {
  const toast = useRef(null);

  const [mailList, setMailList] = useState([]);

  const getMail = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/mail`)
      .then((response) => {
        setMailList(response.data.data);
      });
  };

  const updateMail = (id, status) => {
    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/mail`, {
        id,
        status,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Mail.',
            life: 3000,
          });

          getMail();
        }
      });
  };

  const deleteMail = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/mail`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Mail.',
            life: 3000,
          });
          getMail();
        }
      });
  };

  useEffect(() => {
    getMail();
  }, []);

  return (
    <>
      <Component
        mailList={mailList}
        updateMail={updateMail}
        deleteMail={deleteMail}
      />
      <Toast ref={toast} />
    </>
  );
}
