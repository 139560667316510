import { useEffect, useRef, useState } from 'react';
import Component from '../../components/inquire';
import axios from 'axios';
import { Toast } from 'primereact/toast';

export default function Inquire() {
  const toast = useRef(null);

  const [inquireList, setInquireList] = useState([]);

  const getInquire = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/inquire`)
      .then((response) => {
        setInquireList(response.data.data);
      });
  };

  const deleteInquire = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/inquire`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Inquire.',
            life: 3000,
          });
          getInquire();
        }
      });
  };

  useEffect(() => {
    getInquire();
  }, []);

  return (
    <>
      <Component inquireList={inquireList} deleteInquire={deleteInquire} />
      <Toast ref={toast} />
    </>
  );
}
