import Navigation from '../components/common/Navigation';
import Container from '../containers/artwork';

export default function Artwork() {
  return (
    <Navigation>
      <Container />
    </Navigation>
  );
}
