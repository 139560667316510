import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

import Home from './page/Home';
import Artist from './page/Artist';
import ArtFair from './page/ArtFair';
import Publications from './page/Publications';
import Artwork from './page/Artwork';
import Exhibition from './page/Exhibition';
import OnlineExhibition from './page/OnlineExhibition';
import Inquire from './page/Inquire';
import Journal from './page/Journal';
import Banner from './page/Banner';
import Mail from './page/Mail';
import SignIn from './page/SignIn';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';

function App() {
  return (
    <PrimeReactProvider>
      <Router>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/artist' element={<Artist />} />
          <Route path='/artfair' element={<ArtFair />} />
          <Route path='/publications' element={<Publications />} />
          <Route path='/artwork' element={<Artwork />} />
          <Route path='/exhibition' element={<Exhibition />} />
          <Route path='/online-exhibition' element={<OnlineExhibition />} />
          <Route path='/inquire' element={<Inquire />} />
          <Route path='/journal' element={<Journal />} />
          <Route path='/banner' element={<Banner />} />
          <Route path='/mail' element={<Mail />} />
          <Route path='/signin' element={<SignIn />} />
        </Routes>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
