import { useState } from 'react';

const useInputs = (initialState) => {
  const [inputs, setInputs] = useState(initialState);

  const onChange = (e) => {
    e.persist(); // SyntheticEvent를 비동기적으로 사용하기 위해 필요

    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.target.name]: e.target.value,
    }));
  };

  const reset = (data) => {
    setInputs(data ? data : initialState);
  };

  return {
    inputs,
    onChange,
    reset,
  };
};

export default useInputs;
