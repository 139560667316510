import styled from 'styled-components';
import Lottie from 'react-lottie';
import Color from 'color';
import * as animationData from '../../static/loading.json';

export default function Loading() {
  return (
    <Box>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={150}
        width={150}
      />
    </Box>
  );
}

const Box = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  background-color: ${Color('#000').alpha(0.5).hsl().toString()};
`;
