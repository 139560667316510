import { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import ImageUpload from '../common/ImageUpload';
import Color from 'color';
import { getUTCDate } from '../../library/date.lib';

export default function Journal({
  journalList,
  artists,
  inputs,
  onChange,
  reset,
  postJournal,
  updateJournal,
  deleteJournal,
  selectArtists,
  setSelectArtists,
  dates,
  setDates,
  images,
  setImages,
}) {
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');

  const handleUpdate = ({ data }) => {
    setVisible(true);
    setUpdate(true);
    reset({ ...data });
    setSelectArtists(data.artists);
    setDates(
      String(data.date)
        .split('|')
        .map((date) => {
          return getUTCDate(date).toDate();
        })
    );
    setId(data.id);
    setImages([]);
  };

  const footerContent = (
    <div>
      <Button
        label={update ? 'Delete' : 'No'}
        icon='pi pi-times'
        onClick={() => {
          if (update) {
            deleteJournal(id);
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setSelectArtists([]);
          setDates(null);
          setImages([]);
        }}
        severity='danger'
        text
      />
      <Button
        label={update ? 'Update' : 'Yes'}
        icon='pi pi-check'
        onClick={() => {
          if (update) {
            updateJournal();
          } else {
            postJournal();
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setSelectArtists([]);
          setDates(null);
          setImages([]);
        }}
        autoFocus
      />
    </div>
  );

  const artistTemplate = (artfair) => {
    if (artfair.artists.length === 1) {
      return <div>{artfair.artists[0].name}</div>;
    } else {
      return (
        <div>
          {artfair.artists.map((artist) => {
            return (
              <>
                {artist.name}
                <br />
              </>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Journal</span>

        <Button label='Add Journal' onClick={() => setVisible(true)} />
      </div>

      <DataTable
        value={journalList}
        // paginator
        // rows={10}
        tableStyle={{ minWidth: '50rem' }}
        onRowClick={handleUpdate}
      >
        <Column field='title' header='Title' style={{ width: '30%' }} />
        <Column
          header='Date'
          style={{ width: '20%' }}
          body={(data) => {
            const days = String(data.date).split('|');

            return `${getUTCDate(days[0])
              .format('MM.DD.YYYY')
              .toString()} - ${getUTCDate(days[1])
              .format('MM.DD.YYYY')
              .toString()}`;
          }}
        />
        <Column field='location' header='Location' style={{ width: '25%' }} />
        <Column
          header='Artist'
          style={{ width: '25%' }}
          body={artistTemplate}
        />
      </DataTable>
      <Dialog
        draggable={false}
        header='Journal'
        visible={visible}
        style={{ width: '40vw', height: '70vh' }}
        onHide={() => {
          setVisible(false);
          setUpdate(false);
          reset();
          setSelectArtists([]);
          setDates(null);
          setImages([]);
        }}
        footer={footerContent}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <InputText
            placeholder='title'
            style={{ width: '70%', marginBottom: '5px' }}
            name='title'
            value={inputs.title}
            onChange={onChange}
          />
          <small
            id='username-help'
            style={{
              width: '70%',
              display: 'flex',
              margin: '0 auto 20px auto',
            }}
          >
            * 제목에 특수 문자를 넣을 경우 파일 업로드가 되지 않습니다.
            <br />
            <br />
            만약 특수 문자를 넣어야 하는 경우 특수 문자를 뺀 상태로 파일을
            업로드 하고서
            <br />
            추후에 제목만 다시 업데이트 하면 특수 문자를 추가 할 수 있습니다.
          </small>
          <MultiSelect
            options={artists}
            optionLabel='name'
            filter
            placeholder='Select Artist'
            maxSelectedLabels={3}
            value={selectArtists}
            onChange={(e) => {
              setSelectArtists(e.target.value);
            }}
            style={{ width: '70%', marginBottom: '20px' }}
          />
          <Calendar
            value={dates}
            onChange={(e) => {
              setDates(e.value);
            }}
            selectionMode='range'
            placeholder='Select Dates'
            dateFormat='mm.dd.yy'
            readOnlyInput
            style={{ width: '70%', marginBottom: '20px' }}
          />
          <InputText
            placeholder='location'
            style={{ width: '70%', marginBottom: '20px' }}
            name='location'
            value={inputs.location}
            onChange={onChange}
          />
          <InputText
            placeholder='link'
            style={{ width: '70%', marginBottom: '20px' }}
            name='link'
            value={inputs.link}
            onChange={onChange}
          />
          <InputText
            placeholder='video link'
            style={{ width: '70%', marginBottom: '20px' }}
            name='video_link'
            value={inputs.video_link}
            onChange={onChange}
          />
          <InputTextarea
            placeholder='about'
            style={{
              width: '70%',
              height: '200px',
              resize: 'none',
              marginBottom: '20px',
            }}
            name='about'
            value={inputs.about}
            onChange={onChange}
          />
          {update && inputs.image ? (
            <div
              style={{
                width: '70%',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: Color('#adb5bd').alpha(0.1).hsl().toString(),
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '20px',
              }}
              onClick={() => window.open(inputs.image, '_blank')}
            >
              <img
                src={inputs.image}
                alt='journal_img'
                draggable={false}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  marginRight: '10px',
                  borderRadius: '4px',
                }}
              />
              Previously uploaded file: <i>JOURNAL.JPEG</i>
            </div>
          ) : (
            <></>
          )}
          <ImageUpload
            images={images}
            setImages={setImages}
            style={{ width: '70%' }}
          />
        </div>
      </Dialog>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
