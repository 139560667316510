import { useEffect, useRef, useState } from 'react';
import Component from '../../components/exhibition';
import useInputs from '../../hooks/useInputs';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import upload, { videoUpload } from '../../library/upload.lib';
import utcDate from '../../library/date.lib';
import Loading from '../../components/common/Loading';

export default function Exhibition() {
  const toast = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [exhibitionList, setExhibitionList] = useState([]);
  const [artworks, setArtworks] = useState([]);
  const [selectArtworks, setSelectArtworks] = useState([]);
  const [video, setVideo] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [images, setImages] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    title: '',
    location: '',
    about: '',
  });
  const [dates, setDates] = useState(null);

  const getArtworks = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artwork`)
      .then((response) => {
        setArtworks(response.data.data);
      });
  };

  const getExhibitions = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`)
      .then((response) => {
        setExhibitionList(response.data.data);
      });
  };

  const updateIsJournal = (id, is_journal) => {
    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
        id,
        is_journal,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Exhibition.',
            life: 3000,
          });

          getExhibitions();
        }
      });
  };

  const postExhibition = async () => {
    setIsLoading(true);

    const videoArray = [];
    let array = [];

    if (video.length) {
      for (const file of video) {
        await videoUpload(
          file,
          `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
        )
          .then((s3_response) => {
            videoArray.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.mp4`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    if (images.length) {
      for (const image of images) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
        )
          .then((s3_response) => {
            array.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }

      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
      )
        .then((s3_response) => {
          axios
            .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
              ...inputs,
              video_link: videoArray.length ? videoArray[0] : '',
              artworks: selectArtworks,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.jpeg`,
              images: array,
            })
            .then((response) => {
              if (response.data.status === 200) {
                setIsLoading(false);
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Added Exhibition.',
                  life: 3000,
                });
                getExhibitions();
                reset();
                setImages([]);
                setThumbnail([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }

    if (thumbnail.length) {
      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
      )
        .then((s3_response) => {
          axios
            .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
              ...inputs,
              video_link: videoArray.length ? videoArray[0] : '',
              artworks: selectArtworks,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.jpeg`,
            })
            .then((response) => {
              if (response.data.status === 200) {
                setIsLoading(false);
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Added Exhibition.',
                  life: 3000,
                });
                getExhibitions();
                reset();
                setImages([]);
                setThumbnail([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
          ...inputs,
          video_link: videoArray.length ? videoArray[0] : '',
          artworks: selectArtworks,
          date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setIsLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Added Exhibition.',
              life: 3000,
            });
            getExhibitions();
            reset();
          }
        });
    }
  };

  const updateExhibition = async () => {
    setIsLoading(true);

    const videoArray = [];
    let array = [];

    if (video.length) {
      for (const file of video) {
        await videoUpload(
          file,
          `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
        )
          .then((s3_response) => {
            videoArray.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.mp4`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    if (images.length) {
      for (const image of images) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
        )
          .then((s3_response) => {
            array.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (thumbnail.length) {
        await upload(
          thumbnail[0],
          `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
        )
          .then((s3_response) => {
            axios
              .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
                ...inputs,
                video_link: videoArray.length
                  ? videoArray[0]
                  : inputs.video_link,
                artworks: selectArtworks,
                date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
                thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.jpeg`,
                images: array,
              })
              .then((response) => {
                if (response.data.status === 200) {
                  setIsLoading(false);
                  toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Updated Exhibition.',
                    life: 3000,
                  });
                  getExhibitions();
                  reset();
                  setImages([]);
                  setThumbnail([]);
                }
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
            ...inputs,
            video_link: videoArray.length ? videoArray[0] : inputs.video_link,
            artworks: selectArtworks,
            date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
            images: array,
          })
          .then((response) => {
            if (response.data.status === 200) {
              setIsLoading(false);
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Updated Exhibition.',
                life: 3000,
              });
              getExhibitions();
              reset();
              setImages([]);
            }
          });
      }

      return;
    }

    if (thumbnail.length) {
      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}`
      )
        .then((s3_response) => {
          axios
            .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
              ...inputs,
              video_link: videoArray.length ? videoArray[0] : inputs.video_link,
              artworks: selectArtworks,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/exhibition/${inputs.title}/${s3_response.timestamp}.jpeg`,
              images: [],
            })
            .then((response) => {
              if (response.data.status === 200) {
                setIsLoading(false);
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Updated Exhibition.',
                  life: 3000,
                });
                getExhibitions();
                reset();
                setThumbnail([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
          ...inputs,
          video_link: videoArray.length ? videoArray[0] : inputs.video_link,
          artworks: selectArtworks,
          images: [],
          date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setIsLoading(false);
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Exhibition.',
              life: 3000,
            });
            getExhibitions();
            reset();
          }
        });
    }
  };

  const deleteExhibition = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Exhibition.',
            life: 3000,
          });
          getExhibitions();
          reset();
        }
      });
  };

  useEffect(() => {
    getArtworks();
    getExhibitions();
  }, []);
  return (
    <>
      {isLoading ? <Loading /> : <></>}
      <Component
        artworks={artworks}
        exhibitionList={exhibitionList}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postExhibition={postExhibition}
        updateExhibition={updateExhibition}
        deleteExhibition={deleteExhibition}
        selectArtworks={selectArtworks}
        setSelectArtworks={setSelectArtworks}
        dates={dates}
        setDates={setDates}
        video={video}
        setVideo={setVideo}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        images={images}
        setImages={setImages}
        updateIsJournal={updateIsJournal}
      />
      <Toast ref={toast} />
    </>
  );
}
