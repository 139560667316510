import Navigation from '../components/common/Navigation';
import Container from '../containers/inquire';

export default function Inquire() {
  return (
    <Navigation>
      <Container />
    </Navigation>
  );
}
