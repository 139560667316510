import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { confirmDialog } from 'primereact/confirmdialog';

export default function Mail({ mailList, updateMail, deleteMail }) {
  const confirm = ({ data }) => {
    confirmDialog({
      message: 'Do you want to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteMail(data.id),
      reject: () => {},
    });
  };

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Mailing</span>
      </div>

      <DataTable
        value={mailList}
        // paginator
        // rows={10}
        tableStyle={{ minWidth: '50rem' }}
        onRowClick={confirm}
      >
        <Column field='email' header='Email' style={{ width: '30%' }} />
        <Column
          field='first_name'
          header='First Name'
          style={{ width: '20%' }}
        />
        <Column field='last_name' header='Last Name' style={{ width: '20%' }} />
        <Column
          header='Status'
          style={{ width: '30%' }}
          body={(mail) => (
            <SelectButton
              value={mail.status}
              onChange={(e) => {
                updateMail(mail.id, e.value);
              }}
              options={['pending', 'subscribed']}
            />
          )}
        />
      </DataTable>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
