import { useEffect, useRef, useState } from 'react';
import Component from '../../components/artfair';
import useInputs from '../../hooks/useInputs';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import upload from '../../library/upload.lib';
import utcDate from '../../library/date.lib';

export default function ArtFair() {
  const toast = useRef(null);

  const [artfairList, setArtfairList] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectArtists, setSelectArtists] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [images, setImages] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    title: '',
    location: '',
    video_link: '',
    about: '',
  });
  const [dates, setDates] = useState(null);

  const getArtists = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`)
      .then((response) => {
        setArtists(response.data.data);
      });
  };

  const getArtfair = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`)
      .then((response) => {
        setArtfairList(response.data.data);
      });
  };

  const updateIsJournal = (id, is_journal) => {
    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
        id,
        is_journal,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Art Fair.',
            life: 3000,
          });

          getArtfair();
        }
      });
  };

  const postArtfair = async () => {
    const array = [];

    if (images.length) {
      for (const image of images) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}`
        )
          .then((s3_response) => {
            array.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }

      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}`,
        1500
      )
        .then((s3_response) => {
          axios
            .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
              ...inputs,
              artists: selectArtists,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              images: array,
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}/${s3_response.timestamp}.jpeg`,
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Added Art Fair.',
                  life: 3000,
                });
                getArtfair();
                reset();
                setImages([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }

    await upload(
      thumbnail[0],
      `${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}`,
      1500
    )
      .then((s3_response) => {
        axios
          .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
            ...inputs,
            artists: selectArtists,
            date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
            thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}/${s3_response.timestamp}.jpeg`,
          })
          .then((response) => {
            if (response.data.status === 200) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Added Art Fair.',
                life: 3000,
              });
              getArtfair();
              reset();
              setImages([]);
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateArtfair = async () => {
    const array = [];

    if (images.length) {
      for (const image of images) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}`
        )
          .then((s3_response) => {
            array.push(
              `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (thumbnail.length) {
        await upload(
          thumbnail[0],
          `${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}`,
          1500
        )
          .then((s3_response) => {
            axios
              .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
                ...inputs,
                artists: selectArtists,
                date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
                images: array,
                thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}/${s3_response.timestamp}.jpeg`,
              })
              .then((response) => {
                if (response.data.status === 200) {
                  toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Updated Art Fair.',
                    life: 3000,
                  });
                  getArtfair();
                  reset();
                  setImages([]);
                }
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
            ...inputs,
            artists: selectArtists,
            date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
            images: array,
          })
          .then((response) => {
            if (response.data.status === 200) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Updated Art Fair.',
                life: 3000,
              });
              getArtfair();
              reset();
              setImages([]);
            }
          });
      }

      return;
    }

    if (thumbnail.length) {
      await upload(
        thumbnail[0],
        `${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}`,
        1500
      )
        .then((s3_response) => {
          axios
            .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
              ...inputs,
              artists: selectArtists,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              thumbnail: `https://gallery-easelly.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artfair/${inputs.title}/${s3_response.timestamp}.jpeg`,
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Updated Art Fair.',
                  life: 3000,
                });
                getArtfair();
                reset();
                setImages([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
          ...inputs,
          artists: selectArtists,
          date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Art Fair.',
              life: 3000,
            });
            getArtfair();
            reset();
            setImages([]);
          }
        });
    }
  };

  const deleteArtfair = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Art Fair.',
            life: 3000,
          });
          getArtfair();
          reset();
        }
      });
  };

  useEffect(() => {
    getArtists();
    getArtfair();
  }, []);

  return (
    <>
      <Component
        artists={artists}
        artfairList={artfairList}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postArtfair={postArtfair}
        updateArtfair={updateArtfair}
        deleteArtfair={deleteArtfair}
        selectArtists={selectArtists}
        setSelectArtists={setSelectArtists}
        dates={dates}
        setDates={setDates}
        images={images}
        setImages={setImages}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        updateIsJournal={updateIsJournal}
      />
      <Toast ref={toast} />
    </>
  );
}
