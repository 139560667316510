import Navigation from '../components/common/Navigation';
import Container from '../containers/mail';

export default function Mail() {
  return (
    <Navigation>
      <Container />
    </Navigation>
  );
}
