import { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Color from 'color';
import ImageUpload from '../common/ImageUpload';
import { SelectButton } from 'primereact/selectbutton';

export default function Publications({
  list,
  inputs,
  onChange,
  reset,
  postPublications,
  updatePublications,
  deletePublications,
  images,
  setImages,
  updateIsPurchases,
}) {
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');

  const handleUpdate = ({ data }) => {
    setVisible(true);
    setUpdate(true);
    reset({ ...data });
    setId(data.id);
    setImages([]);
  };

  const footerContent = (
    <div>
      <Button
        label={update ? 'Delete' : 'No'}
        icon='pi pi-times'
        onClick={() => {
          if (update) {
            deletePublications(id);
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setImages([]);
        }}
        severity='danger'
        text
      />
      <Button
        label={update ? 'Update' : 'Yes'}
        icon='pi pi-check'
        onClick={() => {
          if (update) {
            updatePublications();
          } else {
            postPublications();
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setImages([]);
        }}
        autoFocus
      />
    </div>
  );

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Publications</span>

        <Button label='Add Publications' onClick={() => setVisible(true)} />
      </div>

      <DataTable
        value={list}
        paginator
        rows={10}
        tableStyle={{ minWidth: '50rem' }}
        onRowClick={handleUpdate}
      >
        <Column field='title' header='Title' style={{ width: '20%' }} />
        <Column field='sub_title' header='Sub Title' style={{ width: '20%' }} />
        <Column field='text' header='Text' style={{ width: '40%' }} />
        <Column
          header='Purchases'
          style={{ width: '20%' }}
          body={(publications) => (
            <SelectButton
              value={publications.is_purchases ? 'on' : 'off'}
              onChange={(e) => {
                updateIsPurchases(
                  publications.id,
                  e.value === 'on' ? true : false
                );
              }}
              options={['on', 'off']}
            />
          )}
        />
      </DataTable>
      <Dialog
        draggable={false}
        header='Art Fair'
        visible={visible}
        style={{ width: '40vw', height: '70vh' }}
        onHide={() => {
          setVisible(false);
          setUpdate(false);
          reset();
          setImages([]);
        }}
        footer={footerContent}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <InputText
            placeholder='title'
            style={{ width: '70%', marginBottom: '5px' }}
            name='title'
            value={inputs.title}
            onChange={onChange}
          />
          <small
            id='username-help'
            style={{
              width: '70%',
              display: 'flex',
              margin: '0 auto 20px auto',
            }}
          >
            * 제목에 특수 문자를 넣을 경우 파일 업로드가 되지 않습니다.
            <br />
            <br />
            만약 특수 문자를 넣어야 하는 경우 특수 문자를 뺀 상태로 파일을
            업로드 하고서
            <br />
            추후에 제목만 다시 업데이트 하면 특수 문자를 추가 할 수 있습니다.
          </small>
          <InputText
            placeholder='sub title'
            style={{ width: '70%', marginBottom: '20px' }}
            name='sub_title'
            value={inputs.sub_title}
            onChange={onChange}
          />
          <InputTextarea
            placeholder='text'
            style={{
              width: '70%',
              height: '200px',
              resize: 'none',
              marginBottom: '20px',
            }}
            name='text'
            value={inputs.text}
            onChange={onChange}
          />
          {update && inputs.image ? (
            <div
              style={{
                width: '70%',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: Color('#adb5bd').alpha(0.1).hsl().toString(),
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '20px',
              }}
              onClick={() => window.open(inputs.image, '_blank')}
            >
              <img
                src={inputs.image}
                alt='publications_image'
                draggable={false}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  marginRight: '10px',
                  borderRadius: '4px',
                }}
              />
              Previously uploaded file: <i>IMAGE.JPEG</i>
            </div>
          ) : (
            <></>
          )}

          <ImageUpload
            title='Upload publications image from your browser'
            images={images}
            setImages={setImages}
            style={{ width: '70%' }}
          />
        </div>
      </Dialog>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
