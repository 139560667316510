import Navigation from '../components/common/Navigation';
import Container from '../containers/banner';

export default function Banner() {
  return (
    <Navigation>
      <Container />
    </Navigation>
  );
}
