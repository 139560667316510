import { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import CVUpload from '../common/CVUpload';
import Color from 'color';
import { FilterMatchMode } from 'primereact/api';
import ImageUpload from '../common/ImageUpload';

export default function Artist({
  artists,
  inputs,
  onChange,
  reset,
  postArtist,
  updateArtist,
  deleteArtist,
  cv,
  setCv,
  thumbnail,
  setThumbnail,
}) {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />

          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    );
  };

  const handleUpdate = ({ data }) => {
    setVisible(true);
    setUpdate(true);
    reset({ ...data });
    setId(data.id);
    setCv([]);
    setThumbnail([]);
  };

  const footerContent = (
    <div>
      <Button
        label={update ? 'Delete' : 'No'}
        icon='pi pi-times'
        onClick={() => {
          if (update) {
            deleteArtist(id);
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setCv([]);
        }}
        severity='danger'
        text
      />
      <Button
        label={update ? 'Update' : 'Yes'}
        icon='pi pi-check'
        onClick={() => {
          if (update) {
            updateArtist();
          } else {
            postArtist();
          }

          setVisible(false);
          setUpdate(false);
          reset();
          setCv([]);
          setThumbnail([]);
        }}
        autoFocus
      />
    </div>
  );

  const header = renderHeader();

  return (
    <Box>
      <div className={cn('header')}>
        <span className={cn('header-title')}>Artist</span>

        <Button label='Add Artist' onClick={() => setVisible(true)} />
      </div>

      <DataTable
        value={artists}
        paginator
        rows={20}
        header={header}
        filters={filters}
        tableStyle={{ minWidth: '50rem' }}
        onRowClick={handleUpdate}
      >
        <Column field='name' header='Name' style={{ width: '25%' }} />
        <Column field='country' header='Country' style={{ width: '25%' }} />
        <Column
          field='year_of_birth'
          header='Year of birth'
          style={{ width: '25%' }}
        />
        <Column
          header='CV'
          style={{ width: '25%' }}
          body={(data) => (
            <>
              {data.cv ? (
                <div
                  style={{
                    width: '100%',
                    height: 'fit-content',
                    borderRadius: '4px',
                    padding: '10px',
                    backgroundColor: Color('#adb5bd')
                      .alpha(0.1)
                      .hsl()
                      .toString(),
                    fontSize: '14px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    window.open(data.cv, '_blank');
                  }}
                >
                  Previously uploaded file:{' '}
                  <i>CV.PDF (Click to see pdf file.)</i>
                </div>
              ) : (
                <>No cv file has been uploaded.</>
              )}
            </>
          )}
        />
      </DataTable>
      <Dialog
        draggable={false}
        header='Artist'
        visible={visible}
        style={{ width: '40vw', height: '70vh' }}
        onHide={() => {
          setVisible(false);
          setUpdate(false);
          reset();
          setCv([]);
          setThumbnail([]);
        }}
        footer={footerContent}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <InputText
            placeholder='name'
            style={{ width: '70%', marginBottom: '5px' }}
            name='name'
            value={inputs.name}
            onChange={onChange}
          />
          <small
            id='username-help'
            style={{
              width: '70%',
              display: 'flex',
              margin: '0 auto 20px auto',
            }}
          >
            * 이름에 특수 문자를 넣을 경우 파일 업로드가 되지 않습니다.
            <br />
            <br />
            만약 특수 문자를 넣어야 하는 경우 특수 문자를 뺀 상태로 파일을
            업로드 하고서
            <br />
            추후에 이름만 다시 업데이트 하면 특수 문자를 추가 할 수 있습니다.
          </small>
          <InputText
            keyfilter={'int'}
            placeholder='year of birth (only numbers)'
            style={{ width: '70%', marginBottom: '20px' }}
            name='year_of_birth'
            value={inputs.year_of_birth}
            onChange={onChange}
          />
          <InputText
            placeholder='country'
            style={{ width: '70%', marginBottom: '20px' }}
            name='country'
            value={inputs.country}
            onChange={onChange}
          />
          <InputTextarea
            placeholder='about'
            rows={7}
            style={{
              width: '70%',
              height: '300px',
              resize: 'none',
              marginBottom: '20px',
            }}
            name='about'
            value={inputs.about}
            onChange={onChange}
          />
          {update && inputs.thumbnail ? (
            <div
              style={{
                width: '70%',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: Color('#adb5bd').alpha(0.1).hsl().toString(),
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '20px',
              }}
              onClick={() => window.open(inputs.thumbnail, '_blank')}
            >
              <img
                src={inputs.thumbnail}
                alt='artist_thumbnail'
                draggable={false}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  marginRight: '10px',
                  borderRadius: '4px',
                }}
              />
              Previously uploaded file: <i>THUMBNAIL.JPEG</i>
            </div>
          ) : (
            <></>
          )}
          <ImageUpload
            title='Upload artist thumbnail from your browser'
            images={thumbnail}
            setImages={setThumbnail}
            style={{ width: '70%', marginBottom: '20px' }}
          />
          {update && inputs.cv ? (
            <div
              style={{
                width: '70%',
                height: 'fit-content',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: Color('#adb5bd').alpha(0.1).hsl().toString(),
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                userSelect: 'none',
                marginBottom: '20px',
              }}
              onClick={() => window.open(inputs.cv, '_blank')}
            >
              Previously uploaded file: <i>CV.PDF</i>
            </div>
          ) : (
            <></>
          )}
          <CVUpload images={cv} setImages={setCv} style={{ width: '70%' }} />
        </div>
      </Dialog>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #748ffc;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
    border-radius: 10px;
  }

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &-title {
      font-size: 28px;
      font-weight: 600;
      color: #374151;
      margin: 0 auto auto 0;
    }
  }
`;
