import { useEffect, useState } from 'react';
import cn from 'classnames';
import Color from 'color';
import styled from 'styled-components';

import IMAGE_GALLERY from '../../static/image_gallery.svg';

function Item({ src, file }) {
  if (!file) return <></>;

  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

  return (
    <>
      <ImageItem href={src} target='_blank' title={file.name}>
        <span className={cn('name')}>{file.name}</span>
        <span className={cn('size')}>{`${sizeInMB}MB`}</span>
      </ImageItem>
    </>
  );
}

export default function ImageUpload({
  images,
  setImages,
  title = 'Upload image from your browser',
  subtitle = 'Supports: JPG, JPEG, PNG',
  multiple = false,
  style,
}) {
  const [previewImages, setPreviewImages] = useState([]);

  const handleFile = (event) => {
    event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];

    setImages([file]);
    setPreviewImages([URL.createObjectURL(file)]);
  };

  const handleFiles = (event) => {
    event.preventDefault();

    if (!event.target.files || event.target.files.length === 0) return;

    const files = Array.from(event.target.files);

    setImages(files);

    let previews = [];

    for (const index in files) {
      previews = [...previews, URL.createObjectURL(files[index])];
    }

    setPreviewImages(previews);
  };

  useEffect(() => {
    if (images.length !== 0) {
      setPreviewImages(images.map((file) => URL.createObjectURL(file)));
    }
  }, [images]);

  return (
    <>
      <Box style={style}>
        <label className={cn('uploader')}>
          <img
            className={cn('image')}
            src={IMAGE_GALLERY}
            alt={IMAGE_GALLERY}
            draggable={false}
          />

          <span className={cn('title')}>{title}</span>

          <span className={cn('subtitle')}>{subtitle}</span>

          <Input
            type='file'
            accept='image/*'
            multiple={multiple}
            onChange={multiple ? handleFiles : handleFile}
          />
        </label>

        <div
          className={cn('images')}
          style={{ height: multiple ? '10rem' : '3rem' }}
        >
          {previewImages.map((image, index) => {
            return <Item key={index} src={image} file={images[index]} />;
          })}
        </div>
      </Box>
    </>
  );
}

const Box = styled.div`
  width: 30rem;
  min-height: 15rem;
  display: flex;
  flex-direction: column;

  .uploader {
    width: 100%;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: dashed 2px ${Color('#6366f1').alpha(0.7).hsl().toString()};
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    .image {
      width: auto;
      height: 4rem;
      margin-bottom: 1rem;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #6366f1;
      margin-bottom: 0.5rem;
    }

    .subtitle {
      font-weight: 500;
      color: #adb5bd;
    }
  }

  .images {
    width: 100%;
    margin-top: 1rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6366f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${(prop) =>
        Color('#6366f1').alpha(0.3).hsl().toString()};
      border-radius: 4px;
    }
  }
`;

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
`;

const ImageItem = styled.a`
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border-radius: 4px;
  background-color: ${Color('#adb5bd').alpha(0.1).hsl().toString()};
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    margin: auto;
    margin-left: 1rem;
    font-weight: 600;
    color: #6366f1;
  }

  .size {
    margin: auto;
    margin-right: 1rem;
    font-weight: 500;
    color: #adb5bd;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-right: 1rem;
    border-radius: 50%;
    background-color: ${Color('#f03e3e').alpha(0.1).hsl().toString()};
    transition: 0.3s;

    &:hover {
      background-color: ${Color('#f03e3e').alpha(0.2).hsl().toString()};
    }

    &-svg {
      width: 60%;
      height: auto;
      fill: #f03e3e;
    }
  }
`;
